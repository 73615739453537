$ = jQuery;

$(document).ready(function () { 
     if ($('#simulator .wrapper__simulator ').hasClass('simulator_machines')) {

     var field_heure_traitement     = $('#simulateur__machines .simulator__heures_traitement--select'),
         field_jours_traitement     = $('#simulateur__machines .simulator__jours_traitement--select'),
         field_kilo_jour            = $('#simulateur__machines .simulator__kilo_jour--input'),
         field_tonne_an             = $('#simulateur__machines .simulator__tonne_an--input'),
         simulator__select          = $('#simulateur__machines .simulator__kilo_tonnes--select'),
         form                       = $('#simulator .simulator_machines form'),
         btn_submit                 = $('#simulator .wrapper__buton-submit input'),
         result_simulator           = $('#result__simulator');

     result_simulator.removeClass('open');

     //  Begin depend's field : if one has truthy value hide the other
     // field_kilo_jour.on('keyup change', function() {
     //      if ($(this).val()) {
     //           field_tonne_an.parent().parent().hide();    //detach ?
     //      } else {
     //           field_tonne_an.parent().parent().show();   //.prependTo('#simulateur__machines');
     //      }
     // });
     // field_tonne_an.on('keyup change', function() {
     //      if ($(this).val()) {
     //           field_kilo_jour.parent().parent().hide();
     //      } else {
     //           field_kilo_jour.parent().parent().show();
     //      }
     // });

     // image after on button on click
     $(document).not(btn_submit).on('click', function(){
          btn_submit.on('click', function(){ 
               event.stopPropagation();
               btn_submit.addClass('input__hover').parent().addClass('cta__hover');
          });
          if( btn_submit.hasClass('input__hover')) {
               btn_submit.removeClass('input__hover').parent().removeClass('cta__hover');
          }
     });

     field_tonne_an.parent().parent().hide();
     field_kilo_jour.parent().parent().hide();

     simulator__select.on('change', function(){
          var simulator__select_val = simulator__select.val();
          if (simulator__select_val.includes('kilos')) {
               field_tonne_an.val('');
               field_tonne_an.parent().parent().hide();
               field_kilo_jour.parent().parent().show();
          } else {
               field_kilo_jour.val('');
               field_kilo_jour.parent().parent().hide();
               field_tonne_an.parent().parent().show();
          }
     });
     // End depend's field : if one has truthy value hide the other

     // On click submit'button check if field is not empty
     (field_kilo_jour).add(field_tonne_an).after('<p class="field-requiried-js"></p>');
     btn_submit.on('click', function(e){
          if (!field_kilo_jour.val() && !field_tonne_an.val()) {
               e.preventDefault();
               $('.field-requiried-js').text('This field is requiried');
               return false;
          } else {
               $('.field-requiried-js').remove();
          }
     });

     form.on('submit', function(){
          var result_machines_names = $('#result__simulator .result__simulator--name-js'),
               value_kilo_jour = parseFloat(field_kilo_jour.val());

          // check if field is not empty
          if (field_heure_traitement.val()) {
               var value_heure_traitement = parseFloat(field_heure_traitement.val().split(' ', 1));
          }
          if (field_jours_traitement.val()) {
          var value_jours_traitement = parseFloat(field_jours_traitement.val().split(' ', 1));
          }
          if (field_tonne_an.val()) {
               var value_tonne_an = parseFloat(field_tonne_an.val().split(' ', 1));
          }

          ///// Calculs /////
          // Nombre de kilos à traiter par jour  -> Kilos par heure
          var result_kilo_heure__kilo = value_kilo_jour / value_heure_traitement,
          // Nombre de kilos à traiter par jour -> tonnes par an
          result_tonne_an__kilo = value_kilo_jour * value_jours_traitement * 52 / 1000,

          // Nombre de tonnes par an -> Kilos par heure 
          result_kilo_heure__tonne = value_tonne_an / 52 * 1000 / value_jours_traitement / value_heure_traitement,
          // Nombre de tonnes par an -> Kilos par jour
          result_kilo_jour__tonne = value_tonne_an /52 * 1000 / value_jours_traitement;

          ///// Condition display Machines /////
          // If rest is bigger than 250
          var machine_nbr_arr = [],
               result__kilo_heure = 0;

          if (result_kilo_heure__kilo && !result_kilo_heure__tonne) {
               result__kilo_heure = result_kilo_heure__kilo;
          } else if (result_kilo_heure__tonne && !result_kilo_heure__kilo) {
               result__kilo_heure = result_kilo_heure__tonne;
          }

          if (result__kilo_heure >= 250) {
               machine_nbr_arr_250 = '250';
               machine_nbr_arr.push(machine_nbr_arr_250);
               var rest_division = parseInt(result__kilo_heure % 250),
                    number_into_250 = parseInt((result__kilo_heure / 250) >> 0),
                    result_machine_250 = number_into_250 + 'x <span class="font__gradient d-inline">Ecosteryl&nbsp;' + machine_nbr_arr_250 + '</span>',
                    result_machine = result_machine_250;
          } else {
               rest_division = result__kilo_heure;
          }

          // If rest is smaller than 250
          if ( rest_division >= 125 ) {
               machine_nbr_arr_125 = '125';
               machine_nbr_arr.push(machine_nbr_arr_125);
               var number_into_125 = parseInt((rest_division / 125)) >> 0,
                    rest_division = parseInt(rest_division % 125),
                    result_machine_125 = number_into_125 + 'x <span class="font__gradient d-inline">Ecosteryl&nbsp;' + machine_nbr_arr_125 + '</span>',
                    result_machine;
               if ( result__kilo_heure >= 250 && result_machine_250) {
                    result_machine = result_machine_250 + ' + ' + result_machine_125;
               } else {
                    result_machine = result_machine_125;
               } 
          } else {
               // rest_division = result__kilo_heure;
          }
          
          // If rest is smaller than 125
          if ( rest_division < 125 && rest_division !== 0 || result__kilo_heure < 125 ) {

               var number_into_75 = parseInt((rest_division / 75) >> 0),
                    rest_division = parseInt(rest_division % 75);

               if (rest_division < 75 && rest_division !== 0 ) {
                    number_into_75 = number_into_75 + 1;
               }

               machine_nbr_arr_75 = '75';
               machine_nbr_arr.push(machine_nbr_arr_75);

               var result_machine_75 = number_into_75 + 'x <span class="font__gradient d-inline">Ecosteryl&nbsp;' + machine_nbr_arr_75 + '</span>',
                    result_machine;
               if ( result__kilo_heure < 125 ) {
                    result_machine = result_machine_75;
               } else if (rest_division > 0 && rest_division <= 75 && result_machine_125 && result_machine_250 ) {
                    result_machine = result_machine_250 + ' + ' + result_machine_125 + ' + ' + result_machine_75;
               } else if ( result__kilo_heure > 125 && result__kilo_heure < 250  ) {
                    result_machine = result_machine_125 + ' + ' + result_machine_75;
               } else if ( result__kilo_heure % 250 < 125 ) {
                    result_machine = result_machine_250 + ' + ' + result_machine_75;
               }
          }
          // Replace value dynamic result mail
          var field_hidden = $('#result_mail_dynamic');
          result_machine_result = $.parseHTML(result_machine);
          result_machine_result = $(result_machine_result).text();
          field_hidden.val(result_machine_result);

          existCondition = setInterval(function() {  //execute la fonction toute les 1000ms
               if(form.hasClass('sent')) {
                    // Display result text
                    result_machines_names.html(result_machine);

                    // Display result machines
                    $.each(machine_nbr_arr, function( index, value ) {
                         var machine_row = $('#result__simulator .machines--row'),
                              machine = 'ecosteryl-' + value,
                              machineID = '#' + machine,
                              machineIDDiv = $(machineID);
                         machine_row.addClass('d-none');
                         setTimeout(function() { 
                              machineIDDiv.removeClass('d-none');
                         }, 100);
                    });

                    // $('#result').val().html($(result_machine).text()); //#result est le champs caché du plugin "Contact Form 7 - Dynamic Text Extension"
                    result_simulator.addClass('open');

                    $('html, body').animate({
                         scrollTop: result_simulator.offset().top + -20
                     }, 500);
                    clearInterval(existCondition);  
               } 
          }, 1000); // check every 1000ms
          return true; 
     });
     $(document).on('reset', form, function(e) {
          e.preventDefault();
     });  
     };
});